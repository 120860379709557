import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { deleteAssessment, getAssessments } from '../../app/actions/assessActions';
import ConfirmDelete from '../../components/Modals/ConfirmDelete';
import axios from 'axios';
import toast from 'react-hot-toast';
import jsPDF from 'jspdf';
import { useEffect } from 'react';
import useLogout from '../../utils/useLogout';

const PendingReportList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector(state => state.Auth);
    const { assessments } = useSelector(state => state.Assess)
    const [deleteModal, setDeleteModal] = useState(false)

    const [deleteId, setDeleteId] = useState('')

    const deleteAssess = (id) => {
        setDeleteId(id)
        setDeleteModal(true)
    }


    const userlogout = useLogout()

    useEffect(() => {
        userlogout()
    }, [])

    useEffect(() => {
        dispatch(getAssessments())
    }, [])

    const handleOnDelete = () => {
        dispatch(deleteAssessment(deleteId))
        setDeleteModal(false)
        setDeleteId('')
    }


    const mailAssess = (id) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': localStorage.getItem('xat')
            }
        }
        axios.post(`${process.env.REACT_APP_API_HOST}/assessment/send-email/${id}`, {}, config).then(res => {
            toast.success(res.data.message)
        }).catch(err => {
            toast.error(err.response.data.error)
        })
    }


    const removeUnderScore = (key) => {
        return key.replace(/_/g, ' ')
    }

    // const downloadAssessmentReport = (d) => {
    //     const assessment = assessments.find(assessment => assessment._id === d);
    //     const assessContent = data.find(assessment => assessment.id === d).shortReport;

    //     const doc = new jsPDF('p', 'pt', 'a4');

    //     const margin = 10;
    //     const fontSize = 12;

    //     doc.setFontSize(fontSize);

    //     const headingPosition = margin + (fontSize * 2)
    //     let yPosition = margin + (fontSize * 5);

    //     doc.text('Psychological Assessment Report', doc.internal.pageSize.width / 2, headingPosition, { align: 'center' });

    //     const name = `${assessment?.userDetails?.firstName} ${assessment?.userDetails?.lastName}`;
    //     const dob = assessment?.userDetails?.yearOfBirth;
    //     const age = new Date().getFullYear() - dob;
    //     const assessedBy = `${user?.firstName} ${user?.lastName}`;
    //     const assessmentDate = new Date(assessment?.updatedAt).toLocaleDateString();

    //     const patientDetails = `Name: ${name}\n\nDate of Birth: ${dob}\n\nAge: ${age
    //         }\n\nAssessed By: ${assessedBy}\n\nAssessment Date: ${assessmentDate}\n\n`;

    //     const splitPatientDetails = doc.splitTextToSize(patientDetails, doc.internal.pageSize.width - (margin * 3));
    //     doc.text(splitPatientDetails, margin, yPosition);
    //     yPosition += (splitPatientDetails.length + 2) * fontSize;


    //     Object.entries(assessContent).forEach(([key, value]) => {
    //         const text = `${removeUnderScore(key)}:\n ${value}`;
    //         const splitText = doc.splitTextToSize(text, doc.internal.pageSize.width - (margin * 3));
    //         const pageHeight = doc.internal.pageSize.height - (margin * 3);
    //         const textHeight = splitText.length * fontSize;

    //         if (yPosition + textHeight > pageHeight) {
    //             doc.addPage();
    //             yPosition = margin + (fontSize * 2);

    //             if (textHeight > pageHeight) {
    //                 splitText.forEach((text) => {
    //                     doc.text(text, margin, yPosition);
    //                     yPosition += fontSize;
    //                 });
    //                 return;
    //             }
    //         }
    //         doc.text(splitText, margin, yPosition);
    //         yPosition += (splitText.length + (longReport ? 5 : 2)) * fontSize;
    //     });

    //     const signature = user?.signature;
    //     if (signature) {
    //         const imgWidth = 100;
    //         const imgHeight = 50;
    //         doc.addImage(signature, 'PNG', margin, yPosition, imgWidth, imgHeight);
    //         yPosition += imgHeight + margin + 15;
    //     }

    //     const additionalCredentials = user?.additional;
    //     const splitAdditionalCredentials = doc.splitTextToSize(additionalCredentials, doc.internal.pageSize.width - (margin * 3));
    //     doc.text(splitAdditionalCredentials, margin, yPosition);
    //     yPosition += (splitAdditionalCredentials.length + 2) * fontSize;


    //     doc.save('Assessment_Report.pdf');
    // };

    const columns = [
        {
            name: 'First Name',
            selector: row => row.firstName,
            sortable: true,
            width: '150px'
        },
        {
            name: 'Last Name',
            selector: row => row.lastName,
            sortable: true,
            width: '150px'
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true
        },
        {
            name: 'Assessment Forms',
            selector: row => row.assessmentForms,
            sortable: true,
            width: '300px'
        },
        {
            name: 'Status',
            sortable: true,
            cell: row => <div className='d-flex'>
                {
                    row.status === 'pending' ? <span className='badge badge-warning'>Pending</span> : <span className='badge badge-success'>Completed</span>
                }
            </div>,
            center: 'true'
        },
        {
            name: 'Actions',
            cell: row => <div className='d-flex'>
                {
                    row.status === 'completed' ? <>
                        <i className='bx bx-show mx-2 list-icon' title='View Results' onClick={() => navigate(`/pending-reports/details/${row.id}`, { state: { row } })}></i>
                        <Link to={`/review-report/${row.id}`}><div className='btn btn-sm btn-review'>Review Report</div></Link>
                        <i className='bx bx-arrow-to-top list-icon mx-1'></i>

                    </> : <>
                        <Link to={`/pending-reports/${row.id}`}><i className='bx bx-edit-alt mx-2 list-icon' title='Edit Assessment'></i></Link>
                        <i className='bx bx-trash mx-2 list-icon' title='Delete Assessment' style={{ color: "#F24E1E" }} onClick={() => { deleteAssess(row.id) }}></i>
                        <i className='bx bx-mail-send mx-2 list-icon' style={{ color: "#367DB0" }} title='Send Email' onClick={() => { mailAssess(row.id) }}></i></>
                }

            </div>,
            sortable: false,
            center: 'true'
        }
    ];


    const data = assessments?.map(assessment => {
        if (assessment.reportStatus === 'pending') {
            return {
                id: assessment?._id || '',
                firstName: assessment?.patientId?.firstName || '',
                lastName: assessment?.patientId?.lastName || '',
                email: assessment?.patientId?.email || '',
                assessmentForms: assessment?.formIds?.filter(f => f.tier !== 'tier-1')?.map(form => form?.formName).join(', ').concat(', Tier 1') || '',
                status: assessment?.progress?.status || '',
                shortReport: assessment.shortSummary || '',
            };
        } else {
            return null;
        }
    })?.filter(Boolean);



    return (
        <>
            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="d-flex mb-3">
                                <Link to='/dashboard'>
                                    <i className='bx bx-chevron-left back-btn'></i>
                                </Link>
                                <h1>Pending Reports</h1>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="d-flex justify-content-end">
                                {/* <button className="btn btn-primary">Create Report</button> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <DataTable columns={columns} data={data} pagination />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmDelete onClose={() => setDeleteModal(false)} isOpen={deleteModal} onDelete={handleOnDelete} />
        </>
    );
};

export default PendingReportList;
