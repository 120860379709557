import { React, Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HeaderLayout from './components/HeaderLayout';
import Protected from './components/Protected';
import { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSubAccounts } from './app/actions/authActions';
import ForgetPass from './pages/ForgetPass';
import ResetPassword from './pages/ResetPassword';
import PendingReportList from './pages/Reports/PendingReportList';
import ApprovedReportList from './pages/Reports/ApprovedReportList';
import ReviewReport from './pages/Reports/ReviewReport';
import { getApprovedSubAssessments, getAssessments } from './app/actions/assessActions';
import ViewReport from './pages/Reports/ViewReport';
import PendingReportDetails from './pages/Reports/PendingReportDetails';
import CompletedReportDetails from './pages/Reports/CompletedReportDetails';

const Login = lazy(() => import('./pages/Login'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Profile = lazy(() => import('./pages/Profile'));
const Page404 = lazy(() => import('./components/404/Page404'));

function App() {
  console.log("Build Date: 24-June-2024")

  const dispatch = useDispatch()
  const { isAuthenticated } = useSelector(state => state.Auth)

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getAllSubAccounts())
      dispatch(getAssessments())
      dispatch(getApprovedSubAssessments())

    }
    // eslint-disable-next-line
  }, [isAuthenticated])

  return (
    <>
      <Router>
        <Suspense fallback={<>Loading...</>}>
          <Routes>
            <Route element={<HeaderLayout />}>
              <Route path='/profile' element={<Protected><Profile /></Protected>} />
              <Route path='/dashboard' element={<Protected><Dashboard /></Protected>} />
              <Route path='/pending-reports' element={<PendingReportList />} />
              <Route path='/pending-reports/details/:id' element={<PendingReportDetails />} />
              <Route path='/completed-reports' element={<ApprovedReportList />} />
              <Route path='/completed-reports/details/:id' element={<CompletedReportDetails />} />
              {/* <Route path='/pending-reports/:id' element={<EditPendingReport />} /> */}
              <Route path='/review-report/:id' element={<Protected><ReviewReport /></Protected>} />
              <Route path='/view-report/:id' element={<Protected><ViewReport /></Protected>} />
            </Route>
            <Route path='*' element={<Page404 />} />
            <Route path='/' element={<Login />} />
            <Route path='/forget-password' element={<ForgetPass />} />
            <Route path='/change-password/:token' element={<ResetPassword />} />
          </Routes>
        </Suspense>
        <Toaster
          position="top-center"
          reverseOrder={false}
        />
      </Router>
    </>
  );
}

export default App;
