const initialState = {
    loading: false,
    isAuthenticated: false,
    user: {},
    subAccounts: [],
    redirect: false,
};

const Auth = (state = initialState, action) => {
    switch (action.type) {
        case 'SUB_LOGIN_REQUEST':
            return { ...state, loading: true };
        case 'SUB_LOGIN_SUCCESS':
            return { ...state, loading: false, isAuthenticated: true, user: action.user };
        case 'SUB_LOGIN_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'GET_ALL_SUB_ACCOUNTS_REQ':
            return { ...state, loading: true };
        case 'GET_ALL_SUB_ACCOUNTS_SUCCESS':
            return { ...state, loading: false, subAccounts: action.payload };
        case 'GET_ALL_SUB_ACCOUNTS_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'SUB_ACCOUNT_REQ':
            return { ...state, loading: true };
        case 'SUB_ACCOUNT_SUCCESS':
            return { ...state, loading: false, subAccounts: [...state.subAccounts, action.user.user] };
        case 'SUB_ACCOUNT_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'LOGOUT':
            return { ...state, loading: false, isAuthenticated: false, user: {}, subAccounts: [] };
        case 'UPDATE_USER_REQ':
            return { ...state, loading: true };
        case 'UPDATE_USER_SUCCESS':
            return { ...state, loading: false, user: action.user };
        case 'UPDATE_USER_FAILURE':
            return { ...state, loading: false, error: action.error };


        default:
            return state;
    }
};

export default Auth;