import { logout } from "../app/actions/authActions";
import { useDispatch } from "react-redux";

const useLogout = () => {
    const dispatch = useDispatch();
    const handleLogout = () => {
        if (!localStorage.getItem('xat')) {
            dispatch(logout());
        }
    };
    return handleLogout;
};

export default useLogout;
