import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { deleteAssessment } from '../../app/actions/assessActions';
import ConfirmDelete from '../../components/Modals/ConfirmDelete';
import axios from 'axios';
import toast from 'react-hot-toast';

const ApprovedReportList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const API_URL = process.env.REACT_APP_API_HOST;


    const { user } = useSelector(state => state.Auth);

    const { approved } = useSelector(state => state.Assess)

    const [deleteModal, setDeleteModal] = useState(false)

    const [deleteId, setDeleteId] = useState('')

    const deleteAssess = (id) => {
        setDeleteId(id)
        setDeleteModal(true)
    }

    const handleOnDelete = () => {
        dispatch(deleteAssessment(deleteId))
        setDeleteModal(false)
        setDeleteId('')
    }

    const columns = [
        {
            name: 'First Name',
            selector: row => row.firstName,
            sortable: true,
            width: '150px'
        },
        {
            name: 'Last Name',
            selector: row => row.lastName,
            sortable: true,
            width: '150px'
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true
        },
        {
            name: 'Assessment Forms',
            selector: row => row.assessmentForms,
            sortable: true,
            width: '300px'
        },
        {
            name: 'Actions',
            cell: row => <div className='d-flex'>
                {
                    <>
                        <Link to={`/view-report/${row.id}`}><i className='bx bx-show mx-2 list-icon' title='View Reprt'></i></Link>

                        <i className='bx bx-show mx-2 list-icon' style={{ color: 'red' }} title='View Scores' onClick={() => navigate(`/completed-reports/details/${row.id}`, { state: { row } })}></i>

                        <i className='bx bx-arrow-from-top mx-2 list-icon' style={{ color: "#367DB0" }} title='Download Report' onClick={() => downloadAssessmentReport(row.id)}></i>
                    </>
                }

            </div>,
            sortable: false,
            center: 'true'
        }
    ];


    const removeUnderScore = (key) => {
        return key.replace(/_/g, ' ')
    }

    const downloadAssessmentReport = async (d) => {
        const assessment = approved.find(assessment => assessment._id === d);
        const assessContent = assessment?.reportType === 'long' ? assessment?.longSummary : assessment?.shortSummary;

        const content = `
            <html>
                <head>
                    <style>
                        body { font-family: Arial, sans-serif; }
                        h1 { text-align: center; }
                        p { margin: 0 0 10px; }
                        .heading-text { font-size: 18px; margin: 20px 0 5px;}
                    </style>
                </head>
                <body>
                    <h1>Psychological Assessment Report</h1>
                    <p>Name: ${assessment?.patientId?.firstName} ${assessment?.patientId?.lastName}</p>
                    <p>Date of Birth: ${assessment?.userDetails?.yearOfBirth}</p>
                    <p>Age: ${new Date().getFullYear() - assessment?.userDetails?.yearOfBirth}</p>
                    <p>Assessed By: ${user?.firstName} ${user?.lastName}</p>
                    <p>Assessment Date: ${new Date(assessment?.updatedAt).toLocaleDateString()}</p>
                    ${Object.entries(assessContent).map(([key, value]) => `
                        <p class="heading-text"><b>${removeUnderScore(key)}</b></p> <p>  ${Array.isArray(value) ? value.join('<br/>') : value}</p>
                    `).join('')}
                    <br>
                    <img src="${user?.signature}" alt="signature" style="width: 200px; height: 100px; margin: 15px 0 10px 0;">
                    <p>${user?.additional.replace(/\n/g, '<br>')}</p>
                </body>
            </html>
        `;

        const options = {
            format: 'A4',
            margin: {
                top: '0.5in',
                right: '0.5in',
                bottom: '0.5in',
                left: '0.5in'
            }
        };

        try {
            const response = await axios.post(`${API_URL}/generate-pdf`, { content, options }, { responseType: 'blob' });
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Assessment_Report.pdf';
            link.click();
        } catch (error) {
            console.error('Error generating PDF:', error);
        } finally {
            toast.success('Report downloaded successfully!');
        }
    };

    const data = approved?.map(assessment => {
        if (assessment.reportStatus === 'approved') {
            return {
                id: assessment?._id || '',
                firstName: assessment?.patientId?.firstName || '',
                lastName: assessment?.patientId?.lastName || '',
                email: assessment?.patientId?.email || '',
                assessmentForms: assessment?.formIds?.filter(f => f.tier !== 'tier-1').map(form => form?.formName).join(', ').concat(', Tier 1') || '',
                status: assessment?.progress?.status || '',
                shortReport: assessment.shortSummary ? assessment.shortSummary : 'No Report',
                yearOfBirth: assessment?.userDetails?.yearOfBirth || '',
                assessmentDate: new Date(assessment?.updatedAt).toLocaleDateString() || '',
                assessedBy: `${user?.firstName} ${user?.lastName}`,
                assessmentName: `${assessment?.userDetails?.firstName} ${assessment?.userDetails?.lastName}`,
            };
        } else {
            return null;
        }
    })?.filter(Boolean);

    return (
        <>
            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="d-flex mb-3">
                                <Link to='/dashboard'>
                                    <i className='bx bx-chevron-left back-btn'></i>
                                </Link>
                                <h1>Completed Reports</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <DataTable columns={columns} data={data} pagination />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmDelete onClose={() => setDeleteModal(false)} isOpen={deleteModal} onDelete={handleOnDelete} />
        </>
    );
};

export default ApprovedReportList;