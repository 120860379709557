import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import '../styles/Header.css'
import { logout } from '../app/actions/authActions';
import { useDispatch } from 'react-redux';

const Header = () => {
    const redirect = useNavigate()
    const dispatch = useDispatch()

    const onLogout = () => {
        dispatch(logout())
        redirect('/')
    }
    return (
        <nav className="navbar navbar-expand-lg nav-color mb-3">
            <div className="container-fluid">
                <Link to="/">
                    <img className="navbar-brand" src='./assets/logo.svg' alt='logo' />
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
                    <span className="navbar-text">
                        <div className="d-flex gap-3">
                            {/* <button className="header-btn btn-logout" onClick={onLogout}><i className='bx bx-log-out-circle mx-3'></i>Logout</button> */}
                            <ul className="list-unstyled">
                                <li className="dropdown ml-2">
                                    <div className="avatar avatar-md avatar-indicators avatar-online">
                                        <img alt="avatar" src="./assets/profile.svg" className="rounded-circle" />
                                    </div>
                                    <div className="dropdown-menu" aria-labelledby="dropdownUser">
                                        <div className="">
                                            <ul className="list-unstyled">
                                                <li>
                                                    <Link to="/profile" className="dropdown-item">
                                                        <span className="mr-1">
                                                            <i className="bx bx-user dropdown-icon" style={{ color: "#367DB0" }} ></i>
                                                        </span>Profile Settings
                                                    </Link>
                                                </li>
                                                <div className="dropdown-divider"></div>
                                                <li>
                                                    <span className="dropdown-item" onClick={onLogout}><i className="bx bx-log-out-circle dropdown-icon" style={{ color: "#F24E1E" }}></i>Logout</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </span>
                </div>
            </div>
        </nav>
    )
}

export default Header